import { Container, Typography } from "@mui/material";
import React from "react";
import { textColor1 } from "../constants/colors";
import { ReviewContext } from "./ReviewContext";

export default function Reviews({ children, title = "", style = {} }) {
  return (
    <div style={style}>
      <Container style={styles.container} maxWidth="md">
        <Typography style={styles.title}>{title}</Typography>
        <div style={styles.reviewContainer}>
          <ReviewContext.Provider value={styles.review}>
            {children}
          </ReviewContext.Provider>
        </div>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  reviewContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "3vw",
  },
  review: {
    flexBasis: "40%",
    flexGrow: 1,
    minWidth: "280px",
  },
  title: {
    color: textColor1,
    textAlign: "center",
    marginTop: "3vh",
    marginBottom: "5vh",
    fontWeight: "bold",
    fontSize: "calc(1.2rem + 1.2vw)",
  },
};
