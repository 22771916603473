import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import "../App.css";
import ContactInfo from "../components/ContactInfo";
import Header from "../components/header/Header";
import SiteMap from "../components/SiteMap";
import {
  bannerPagePhotoHeight_LG,
  bannerPagePhotoHeight_SM,
} from "../constants/config";

export default function Contact() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={styles.container}>
      <Header activeHeaderButtonIndex={3} />
      <Box component="main">
        <Toolbar />
        <div
          className="backimg backimgcontact"
          style={{
            ...styles.titleContainer,
            height: smscreen
              ? bannerPagePhotoHeight_SM
              : bannerPagePhotoHeight_LG,
          }}
        >
          <Typography style={styles.title}>Contact Us</Typography>
        </div>
      </Box>
      <Container style={styles.contactContainer} maxWidth="lg">
        <ContactInfo style={styles.contactInfo} />
        <iframe
          style={styles.map}
          loading="lazy"
          src="https://maps.google.com/maps?q=5702%20KP%20Nachtegaallaan%2052&amp;t=m&amp;z=16&amp;output=embed&amp;iwloc=near"
          title="5702 KP Nachtegaallaan 52"
          aria-label="5702 KP Nachtegaallaan 52"
        ></iframe>
      </Container>
      <SiteMap />
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(30,24,16,0.7)",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "calc(2rem + 3vw)",
    paddingLeft: "2vw",
    paddingRight: "2vw",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
    gap: "30px",
    marginTop: "5vh",
    marginBottom: "5vh",
    padding: "2rem",
    height: "100%",
    flexGrow: 1,
  },
  contactInfo: {
    flexGrow: 1,
    flex: 1,
    width: "100%",
    height: "100%",
    minWidth: "250px",
    alignSelf: "center",
  },
  map: {
    minWidth: "250px",
    flexGrow: 1,
    flex: 1,
    width: "100%",
    minHeight: "300px",
    borderWidth: 0,
  },
};
