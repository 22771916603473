import React, { useContext } from "react";
import { Typography } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Avatar from "@mui/material/Avatar";
import { textColor1, subTitleColor } from "../constants/colors";
import { ReviewContext } from "./ReviewContext";

export default function ReviewComponent({ children, author, avatar }) {
  const reviewStyle = useContext(ReviewContext);

  return (
    <div style={{ ...styles.root, ...reviewStyle }}>
      <div style={styles.quote}>
        <FormatQuoteIcon />
        <Typography style={styles.text}>{children}</Typography>
      </div>
      <div style={styles.author}>
        <Avatar alt={author} src={avatar} />
        <Typography style={styles.authorText}>{author}</Typography>
      </div>
    </div>
  );
}

const styles = {
  root: {
    display: "grid",
    overflow: "hidden",
    gridTemplateColumns: "repeat(1, 1fr)",
    gridAutoRows: "1fr",
    gridColumnGap: "5px",
    gridRowGap: "5px",
  },
  quoteContainer: {
    display: "flex",
    fontSize: "2rem",
    marginTop: "5vh",
  },
  quote: {
    color: textColor1,
    flex: 1,
  },
  text: {
    marginTop: "5px",
    flex: 1,
    color: "black",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  author: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2vh",
  },
  authorText: {
    marginLeft: "1.5vw",
    marginTop: "10px",
    color: "black",
    backgroudColor: subTitleColor,
    fontSize: "1rem",
    textTransform: "uppercase",
  },
};
