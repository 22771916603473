import { useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import "../App.css";
import { subTitleColor, textColor1 } from "../constants/colors";
import aboutusimg from "../resources/home_port.jpg";
import NumberStats from "./NumberStats";

export default function AboutusFullVersion() {
  const theme = useTheme();
  const mdscreen = useMediaQuery(theme.breakpoints.down(1024));

  return (
    <div style={{ ...styles.root, marginBottom: mdscreen ? "7vh" : "13vh" }}>
      <Container style={styles.container} maxWidth="lg">
        <div style={{ ...styles.port, minWidth: mdscreen ? "100%" : "300px" }}>
          <img src={aboutusimg} style={styles.img} alt="aboutus" />
        </div>
        <div style={styles.info}>
          <Typography style={styles.aboutusText}>
            Our names are Allan and Trishaira Lau and we are the founders of
            Altri Consultancy in the Netherlands. We are both from Aruba and are
            raised by family who owned different businesses. As entrepreneurs,
            we have studied almost 10 years of business and would like to help
            others make a difference in their lives with excellence.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            Our families’ hard work in business was the inspiration for us to
            live in the Netherlands and to learn more about entrepreneurship. We
            made the decision to reach this goal by studying at Hogeschool van
            Arnhem & Nijmegen (H.A.N). We wanted to be an example for the future
            generation and to leave a legacy behind.
            <br />
            <br />
            After graduating with a Bachelor of Arts, we noticed that Christian
            entrepreneurs were struggling to take the first steps or were stuck
            with their promotions. With our years of experience and study, we
            wanted to provide advertisement services to support start-ups in
            building their businesses.
            <br />
            <br />
            The most important question that you need to ask yourself is: What
            are you waiting for? Take the first step and make an appointment
            with us. We will make an impact on your business.
          </Typography>
          <Divider style={{ ...styles.divider, ...styles.divider2 }} />
          <NumberStats />
        </div>
      </Container>
    </div>
  );
}

const styles = {
  root: {
    backgroundColor: "white",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5vh",
  },
  port: {
    textAlign: "center",
    flex: 1,
    maxWidth: "500px",
    marginTop: "5vh",
    marginBottom: "5vh",
  },
  info: {
    height: "100%",
    flex: 1,
    flexGrow: 1,
    maxWidth: "500px",
    marginLeft: "2vw",
    marginRight: "2vw",
  },
  img: {
    maxWidth: "90%",
    height: "auto",
  },
  divider: {
    color: "black",
    backgroundColor: "black",
    height: "1px",
  },
  divider2: {
    marginTop: "5vh",
  },
  aboutusText: {
    color: "black",
    fontSize: "1rem",
    marginTop: "2vh",
    fontWeight: "bold",
    textAlign: "justify",
  },
  aboutusSubText: {
    color: subTitleColor,
    marginTop: "2vh",
    textAlign: "justify",
  },
};
