import { Container, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useRef } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { subTitleColor, textColor1 } from "../constants/colors";
import { maxCardWidthMobile } from "../constants/config";
import card1 from "../resources/home-card1.jpg";
import card2 from "../resources/home-card2.jpg";
import card3 from "../resources/home-card3.jpg";

function BusinessCardContainer({ smscreen, children }) {
  const swiperRef = useRef(null);
  if (smscreen) {
    return (
      <ScrollTrigger
        onEnter={() => {
          setTimeout(() => {
            swiperRef.current.swiper.autoplay.start();
          }, 300);
        }}
      >
        <Swiper
          ref={swiperRef}
          pagination={true}
          loop={true}
          autoplay={false}
          slidesPerView={1}
          spaceBetween={50}
          modules={[Pagination]}
          style={{
            ...styles.cardsContainer,
            flexBasis: "100%",
            width: "100%",
            maxWidth: maxCardWidthMobile,
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingBottom: "40px",
          }}
        >
          {React.Children.map(children, (c, i) => {
            let autoplay = 1500;
            if (i === children.length - 1) {
              autoplay = 3000;
            }
            return (
              <SwiperSlide data-swiper-autoplay={autoplay} key={`slide ${i}`}>
                {c}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ScrollTrigger>
    );
  } else {
    return (
      <div
        style={{
          ...styles.cardsContainer,
          flexBasis: 1,
          maxWidth: "100%",
        }}
      >
        {children}
      </div>
    );
  }
}

function BusinessCard({ img, title, subtitle, text, smscreen }) {
  return (
    <Paper
      style={{ ...styles.paperContainer, flexBasis: smscreen ? "100%" : 1 }}
      elevation={4}
    >
      <div style={styles.paperTitleContainer}>
        <Typography style={styles.paperTitle}>{title}</Typography>
      </div>
      <img style={styles.img} src={img} alt="business" />
      <Typography style={styles.paperSubtitle}>{subtitle}</Typography>
      <Typography style={styles.paperText}>{text}</Typography>
    </Paper>
  );
}

export default function BusinessCards({ style }) {
  const theme = useTheme();
  const mdscreen = useMediaQuery(theme.breakpoints.down(680));

  return (
    <div style={style}>
      <Container style={styles.container} maxWidth="lg">
        <Typography style={styles.title}>Our Business Services</Typography>
        <Typography style={styles.text}>
          The choices you make define your achievements
        </Typography>
        <BusinessCardContainer smscreen={mdscreen}>
          <BusinessCard
            smscreen={mdscreen}
            img={card1}
            title="Business Photography"
            subtitle={`COMMERCIAL OR EVENT
PHOTOGRAPHY`}
            text="Attract potential clients to your platforms"
          />
          <BusinessCard
            smscreen={mdscreen}
            img={card2}
            title="Network Advertisement"
            subtitle={`CONNECTING BUSINESSES
TOGETHER`}
            text="Enlarge exposure by building relationship"
          />
          <BusinessCard
            smscreen={mdscreen}
            img={card3}
            title="Sales Promotion"
            subtitle={`STRATEGIC
ADVICE`}
            text="Increase your business performance"
          />
        </BusinessCardContainer>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "2vw",
    alignSelf: "center",
  },
  title: {
    color: textColor1,
    textAlign: "center",
    marginTop: "3vh",
    fontWeight: "bold",
    fontSize: "calc(1.3rem + 1.61vw)",
  },
  text: {
    color: "black",
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: "2vh",
    fontWeight: "bold",
  },
  paperContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "7vh",
    flexGrow: 1,
    width: "100%",
    textAlign: "center",
  },
  paperTitleContainer: {
    width: "100%",
    flex: 1,
    color: textColor1,
    marginTop: "3vh",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    fontWeight: "bold",
    fontSize: "1.25rem",
    minHeight: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperTitle: {
    color: textColor1,
    fontWeight: "bold",
    fontSize: "min(1.4rem, calc(1rem + 1.1vw))",
  },
  paperSubtitle: {
    color: subTitleColor,
    marginTop: "2vh",
    whiteSpace: "pre-wrap",
    fontSize: "0.7rem",
    minHeight: "35px",
  },
  paperText: {
    color: "black",
    fontSize: "1.1rem",
    marginLeft: "1.5vw",
    marginRight: "1.5vw",
    marginTop: "3vh",
    marginBottom: "3vh",
    fontWeight: "bold",
    minHeight: "60px",
    height: "100%",
    flexGrow: 1,
  },
  img: {
    marginTop: "2vh",
    maxWidth: "100%",
    height: "auto",
  },
};
