import React from "react";
import Header from "../components/header/Header";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { backgroundColor } from "../constants/colors";
import MatthewBanner from "../components/MatthewBanner";
import Reviews from "../components/Reviews";
import SiteMap from "../components/SiteMap";
import ServiceComponents from "../components/ServiceComponents";
import ReviewComponent from "../components/ReviewComponent";
import avatar1 from "../resources/service_rev1.jpg";
import avatar2 from "../resources/service_rev2.jpg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../App.css";
import {
  bannerPagePhotoHeight_LG,
  bannerPagePhotoHeight_SM,
} from "../constants/config";

export default function Services() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={styles.container}>
      <Header activeHeaderButtonIndex={1} />
      <Box component="main">
        <Toolbar />
        <div
          className="backimg backimgservice"
          style={{
            ...styles.titleContainer,
            height: smscreen
              ? bannerPagePhotoHeight_SM
              : bannerPagePhotoHeight_LG,
          }}
        >
          <Typography style={styles.title}>Services</Typography>
        </div>
      </Box>
      <MatthewBanner
        text="The most important investment you can make is in your business"
        subtitle="-Ecclesiastes 11:2"
        backgroundColor={backgroundColor}
      />
      <ServiceComponents />
      <Reviews style={styles.reviews} title="What they say about our services">
        <ReviewComponent author="esdras constant" avatar={avatar1}>
          Warm team, results oriented, exceeded my expectations and worth
          recognition by all entrepreneurs.
        </ReviewComponent>
        <ReviewComponent author="Solaince Cornelia" avatar={avatar2}>
          Het was geweldig! Naast een gezegende en leerzame avond was het voor
          mij een heerlijke dagje uit! Volgende keer ben ik er weer bij.
        </ReviewComponent>
      </Reviews>
      <SiteMap />
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(30,24,16,0.7)",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "calc(2rem + 3vw)",
    paddingLeft: "2vw",
    paddingRight: "2vw",
  },
  reviews: {
    backgroundColor: backgroundColor,
    paddingTop: "5vh",
    marginTop: "5vh",
  },
};
