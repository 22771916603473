import React from "react";
import Button from "@mui/material/Button";
import { createUseStyles } from "react-jss";
import { activeHeaderButtonColor } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";

export default function ButtonLink({ isActive, text, path }) {
  const navigate = useNavigate();
  const classes = createUseStyles({
    button: {
      color: isActive ? activeHeaderButtonColor : "black",
      marginRight: "1.5vw",
      marginLeft: "1.5vw",
      "&:hover": {
        color: activeHeaderButtonColor,
        backgroundColor: "transparent",
      },
    },
  })();
  return (
    <Button
      onClick={() => navigate(path)}
      className={classes.button}
      variant="text"
    >
      {text}
    </Button>
  );
}
