import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { createUseStyles } from "react-jss";
import "../App.css";
import {
  backgroundColor,
  subTitleColor,
  textColor1,
  textColor2,
  activeHeaderButtonColor,
} from "../constants/colors";
import aboutusimg from "../resources/allan_seminar.jpg";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Events() {
  const classes = useStyles();
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("634"));

  const [open, setOpen] = React.useState(false);

  return (
    <div style={styles.root}>
      <Container style={styles.container} maxWidth="lg">
        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
        >
          <Fab
            style={styles.fab}
            color="primary"
            aria-label="add"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </Fab>
          <div style={styles.dialogContainer}>
            <img
              onClick={() => setOpen(true)}
              src={aboutusimg}
              style={{ height: "100%" }}
              alt="aboutus"
            />
          </div>
        </Dialog>
        <div onClick={() => setOpen(true)} style={styles.port}>
          {smscreen && (
            <Typography style={styles.aboutusTitleMobile}>
              Upcoming Event
            </Typography>
          )}
          <img src={aboutusimg} style={styles.img} alt="aboutus" />
        </div>
        <div style={styles.info}>
          {!smscreen && (
            <>
              <Divider style={styles.divider} />
              <Typography style={styles.aboutusTitle}>
                Upcoming Event
              </Typography>
            </>
          )}
          <Typography style={styles.aboutusText}>
            We are thrilled to invite you to our highly-anticipated business
            seminar, designed to equip you with business-changing tools,
            strategies, and techniques to thrive in today’s rapidly-evolving
            market. This is a must-attend event for anyone who wants to start a
            business or has a start-up business.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            Our seminar features two distinguished and powerful business experts
            who will cover a wide range of topics, including leadership,
            innovation, experience, and much more. You will walk away with a new
            perspective on the challenges facing your industry as well as
            practical tools and strategies to help you overcome those challenges
            and achieve your business objectives.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            However, our seminar is not just about learning. It is also about
            networking and connecting with like-minded professionals who share
            your passion for success. You will have the opportunity to meet and
            exchange ideas with other business leaders, make valuable
            connections and build relationships that will last a lifetime.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            Mark your calendars and join us for an unforgettable business
            seminar that will transform your business and propel your career to
            new heights. We encourage you to register early to secure your spot
            as space is limited.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            Registration deadline is the 17th of June, 2023.
          </Typography>
          <Typography style={styles.aboutusSubText}>
            To register, follow the instruction on this link:{" "}
            <a
              href="https://forms.gle/QfmWEACX67JNAHje9"
              target="_blank"
              rel="noreferrer"
            >
              https://forms.gle/QfmWEACX67JNAHje9
            </a>
          </Typography>
          <Button
            href="https://forms.gle/QfmWEACX67JNAHje9"
            target="_blank"
            className={classes.readmore}
            variant="outlined"
          >
            Register
          </Button>
          <Divider style={{ ...styles.divider, ...styles.divider2 }} />
        </div>
      </Container>
    </div>
  );
}

const useStyles = createUseStyles({
  readmore: {
    color: textColor2,
    borderColor: textColor2,
    marginTop: "5vh",
    width: "200px",
    height: "48px",
    "&:hover,&:focus,&:active": {
      backgroundColor: textColor2,
      borderColor: textColor2,
      color: "white",
    },
  },
});

const styles = {
  root: {
    backgroundColor: backgroundColor,
    paddingTop: "5vh",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-5vh",
    marginBottom: "5vh",
  },
  port: {
    textAlign: "center",
    flex: 1,
    minWidth: "280px",
    marginTop: "5vh",
    cursor: "pointer",
  },
  info: {
    marginTop: "5vh",
    textAlign: "center",
    height: "100%",
    flex: 1,
    flexGrow: 1,
    minWidth: "280px",
    marginLeft: "2vw",
    marginRight: "2vw",
  },
  img: {
    maxWidth: "80%",
    height: "auto",
  },
  divider: {
    color: "black",
    backgroundColor: "black",
    height: "1px",
  },
  divider2: {
    marginTop: "5vh",
  },
  aboutusTitle: {
    color: textColor1,
    textAlign: "left",
    marginTop: "3vh",
    fontWeight: "bold",
    fontSize: "calc(1.5rem + 2vw)",
  },
  aboutusTitleMobile: {
    color: textColor1,
    textAlign: "center",
    marginBottom: "3vh",
    fontWeight: "bold",
    fontSize: "calc(1.5rem + 2vw)",
  },
  aboutusText: {
    color: "black",
    fontSize: "1rem",
    marginTop: "2vh",
    fontWeight: "bold",
    textAlign: "left",
  },
  aboutusSubText: {
    textAlign: "left",
    fontSize: "15ptx",
    color: subTitleColor,
    marginTop: "2vh",
  },
  fab: {
    position: "fixed",
    margin: "10px",
    backgroundColor: activeHeaderButtonColor,
  },
  dialogContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
