import React from "react";
import Header from "../components/header/Header";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { backgroundColor } from "../constants/colors";
import MatthewBanner from "../components/MatthewBanner";
import SiteMap from "../components/SiteMap";
import AboutusFullVersion from "../components/AboutusFullVersion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "../App.css";
import {
  bannerPagePhotoHeight_LG,
  bannerPagePhotoHeight_SM,
} from "../constants/config";

export default function About() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={styles.container}>
      <Header activeHeaderButtonIndex={2} />
      <Box component="main">
        <Toolbar />
        <div
          className="backimg backimgaboutus"
          style={{
            ...styles.titleContainer,
            height: smscreen
              ? bannerPagePhotoHeight_SM
              : bannerPagePhotoHeight_LG,
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography style={styles.title}>About Us</Typography>
        </div>
      </Box>
      <MatthewBanner
        text="Our vision is to equip and build start-up entrepreneurs in achieving great impact within the marketplace"
        subtitle="-Ephesians 4:12"
        backgroundColor={backgroundColor}
      />
      <AboutusFullVersion />
      <SiteMap />
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100vh",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(30,24,16,0.7)",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "calc(2rem + 3vw)",
    paddingLeft: "2vw",
    paddingRight: "2vw",
  },
};
