import React from "react";
import { backgroundColor } from "../constants/colors";
import { Link, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function SiteMap() {
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        ...styles.root,
        justifyContent: smscreen ? "center" : "space-between",
      }}
    >
      <div style={styles.leftItems}>
        <Link
          textAlign={{ xs: "center", sm: "left" }}
          minWidth={{ xs: "100%", sm: "1px" }}
          color="inherit"
          href="#"
          underline="none"
        >
          Privacy Policy
        </Link>
        <Link
          textAlign={{ xs: "center", sm: "left" }}
          minWidth={{ xs: "100%", sm: "1px" }}
          color="inherit"
          href="#"
          underline="none"
        >
          Terms & Condition
        </Link>
      </div>
      <Typography
        textAlign={{ xs: "center", sm: "left" }}
        style={styles.rightItems}
      >
        ©2023 Altri Consultancy. All right reserved.
      </Typography>
    </div>
  );
}

const styles = {
  root: {
    width: "100%",
    backgroundColor: backgroundColor,
    paddingBottom: "20px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftItems: {
    minWidth: "100px",
    display: "flex",
    gap: "20px",
    marginLeft: "5vw",
    color: "black",
    marginTop: "2vh",
    flexWrap: "wrap",
    textAlign: "center",
  },
  rightItems: {
    minWidth: "100px",
    marginRight: "5vw",
    marginLeft: "5vw",
    marginTop: "2vh",
  },
};
