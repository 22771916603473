import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import Typography from "@mui/material/Typography";
import { textColor1 } from "../constants/colors";

export default function NumberStats() {
  const [startAnimation, setStartAnimation] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() =>
        setTimeout(() => {
          setStartAnimation(true);
        }, "100")
      }
    >
      <div style={styles.numberContainer}>
        <div style={styles.numbers}>
          {startAnimation && (
            <Typography className="number" style={styles.numberDigit} />
          )}
          <Typography style={styles.numberText}>Years of Experience</Typography>
        </div>
        <div style={styles.numbers}>
          {startAnimation && (
            <Typography className="numberB" style={styles.numberDigit} />
          )}
          <Typography style={styles.numberText}>
            Certification in Business
          </Typography>
        </div>
      </div>
    </ScrollTrigger>
  );
}

const styles = {
  numberContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5vh",
    flex: 1,
  },
  numbers: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  numberDigit: {
    flexBasis: "20%",
    color: textColor1,
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "min(calc(1.3rem + 1.4vw), 2rem)",
    width: "100%",
    marginRight: "calc(1.2vw + 15px)",
  },
  numberText: {
    flexBasis: "70%",
    color: "black",
    fontSize: "min(calc(0.8rem + 0.8vw), 1.5rem)",
    textAlign: "left",
    fontWeight: "bold",
    alignSelf: "center",
  },
};
