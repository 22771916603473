import { Box, Container, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { backgroundColor, textColor1 } from "../constants/colors";
import { maxCardWidthMobile } from "../constants/config";
import serviceImg1 from "../resources/home-card1.jpg";
import serviceImg2 from "../resources/home-card2.jpg";
import serviceImg3 from "../resources/home-card3.jpg";

function ImageBox({ src }) {
  return (
    <Box alignSelf="center" maxWidth={{ xs: "100%", sm: "250px", md: "300px" }}>
      <img style={styles.serviceImg} src={src} alt="service1" />
    </Box>
  );
}

function ContentBox({ ltr = false, title, subtitle, text }) {
  return (
    <Box
      style={styles.service}
      textAlign={{ xs: "center", sm: "left" }}
      flexBasis={{ xs: "100%", sm: "40%", md: "50%" }}
      marginLeft={{ xs: 0, sm: ltr ? 0 : "5vw" }}
      marginRight={{ xs: 0, sm: ltr ? "5vw" : 0 }}
      paddingX={{ xs: "10px", sm: "10px", md: 0 }}
    >
      <Typography
        variant="h5"
        style={styles.title}
        fontSize={{ xs: "22px", sm: "24px", lg: "28px" }}
      >
        {title}
      </Typography>
      <Typography
        style={styles.subtitle}
        fontSize={{ xs: "14px", sm: "18px", lg: "22px" }}
      >
        {subtitle}
      </Typography>
      <Box paddingX={{ xs: "5px", sm: 0 }}>
        <Typography
          fontSize={{ xs: "15px", sm: "18px", md: "20px" }}
          style={styles.text}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

function SingleService({ ltr, img, title, subtitle, text }) {
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      style={styles.root}
      width={{ md: "100%" }}
      alignSelf={{ xs: "center", sm: "center" }}
      backgroundColor={{ xs: "white", sm: ltr ? "white" : backgroundColor }}
      paddingTop={{ xs: 0, sm: ltr ? 0 : "2vh" }}
      paddingBottom={{ xs: 0, sm: ltr ? 0 : "2vh" }}
    >
      <Container maxWidth="md">
        <Box
          justifyContent={{ sm: "center", md: "space-between" }}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          <Paper
            style={{
              ...styles.serviceContainer,
              maxWidth: smscreen ? maxCardWidthMobile : "100%",
            }}
            elevation={smscreen ? 4 : 0}
          >
            {!ltr || smscreen ? (
              <>
                <ImageBox src={img} />
                <ContentBox title={title} subtitle={subtitle} text={text} />
              </>
            ) : (
              <>
                <ContentBox
                  ltr={true}
                  title={title}
                  subtitle={subtitle}
                  text={text}
                />
                <ImageBox src={img} />
              </>
            )}
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default function ServiceComponents() {
  return (
    <>
      <SingleService
        img={serviceImg1}
        ltr={true}
        title="Business Photography"
        subtitle="Event, professional, commercial, personal"
        text="We take extraordinary photo’s for your business platforms to attract potential clients."
      />
      <SingleService
        img={serviceImg2}
        ltr={false}
        title="Network Advertisement"
        subtitle="Connecting businesses together"
        text="We organize business activities whereby business owners connect with other entrepreneurs to enlarge exposure."
      />
      <SingleService
        img={serviceImg3}
        ltr={true}
        title="Sales Promotion"
        subtitle="Strategic advice"
        text="We do market research to discover which advertisement approaches will increase your business performance."
      />
    </>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "4vh",
  },
  serviceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "inherit",
    backgroundColor: "inherit",
    flex: 1,
    flexWrap: "inherit",
  },
  service: {
    flexGrow: 1,
  },
  serviceImg: {
    width: "100%",
    height: "auto",
    alignSelf: "inherit",
  },
  title: {
    color: textColor1,
    marginTop: "3vh",
    fontWeight: "bold",
  },
  subtitle: {
    color: "#54595f",
    fontWeight: "bold",
  },
  text: {
    marginTop: "3vh",
    marginBottom: "3vh",
    padding: "inherit",
    fontFamily: "'Roboto', Sans-serif",
    fontWeight: "300",
    textAlign: "left",
  },
  component: {
    backgroundColor: "white",
  },
  componentAlt: {
    backgroundColor: backgroundColor,
  },
};
