import React from "react";
import { Box, Typography, Divider, Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import Avatar from "@mui/material/Avatar";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { textColor1 } from "../constants/colors";
import {
  EMAIL,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TEL,
  YOUTUBE,
} from "../constants/links";

export default function ContactInfo({ style }) {
  return (
    <Box style={{ ...styles.container, ...style }}>
      <Link underline="none" href={TEL} style={styles.contactContainer}>
        <Avatar style={styles.iconContainer}>
          <PhoneIcon style={styles.icon} />
        </Avatar>
        <Typography style={styles.text}>+31-653254977</Typography>
      </Link>
      <Divider style={styles.divider} />
      <Link underline="none" href={EMAIL} style={styles.contactContainer}>
        <Avatar style={styles.iconContainer}>
          <EmailIcon style={styles.icon} />
        </Avatar>
        <Typography style={styles.text}> info@altriconsultancy.com </Typography>
      </Link>
      <Divider style={styles.divider} />
      <div
        style={{
          ...styles.contactContainer,
          alignSelf: "center",
        }}
      >
        <Link
          style={styles.socialmedia}
          underline="none"
          href={FACEBOOK}
          target="_blank"
        >
          <Avatar style={styles.facebook} variant="rounded">
            <FacebookIcon style={styles.icon} />
          </Avatar>
        </Link>
        <Link
          style={styles.socialmedia}
          underline="none"
          href={LINKEDIN}
          target="_blank"
        >
          <Avatar style={styles.linkedin} variant="rounded">
            <LinkedInIcon style={styles.icon} />
          </Avatar>
        </Link>
        <Link
          style={styles.socialmedia}
          underline="none"
          href={INSTAGRAM}
          target="_blank"
        >
          <Avatar style={styles.instagram} variant="rounded">
            <InstagramIcon style={styles.icon} />
          </Avatar>
        </Link>
        <Link
          style={styles.socialmedia}
          underline="none"
          href={YOUTUBE}
          target="_blank"
        >
          <Avatar style={styles.youtube} variant="rounded">
            <YouTubeIcon style={styles.icon} />
          </Avatar>
        </Link>
      </div>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contactContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  contactIcon: {
    flexBasis: "20%",
  },
  contactText: {
    flexBasis: "80%",
  },
  iconContainer: {
    borderColor: textColor1,
    backgroundColor: textColor1,
    padding: "1.8rem",
  },
  icon: {
    color: "white",
    fontSize: "2rem",
  },
  text: {
    color: "black",
    fontSize: "calc(0.8rem + 0.8vw)",
  },
  divider: {
    height: "1px",
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "2vw",
    marginLeft: "2vw",
  },
  socialmedia: {
    flex: 1,
  },
  facebook: {
    borderColor: "#3b5998",
    backgroundColor: "#3b5998",
    padding: "1.8rem",
  },
  linkedin: {
    borderColor: "#0077B5",
    backgroundColor: "#0077B5",
    padding: "1.8rem",
  },
  instagram: {
    padding: "1.8rem",
    background:
      "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
  },
  youtube: {
    borderColor: "#cd201f",
    backgroundColor: "#cd201f",
    padding: "1.8rem",
  },
};
