import React from "react";
import Header from "../components/header/Header";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { backgroundColor, textColor1 } from "../constants/colors";
import MatthewBanner from "../components/MatthewBanner";
import Events from "../components/Events";
import BusinessCards from "../components/BusinessCards";
import Reviews from "../components/Reviews";
import SiteMap from "../components/SiteMap";
import ReviewComponent from "../components/ReviewComponent";
import avatar1 from "../resources/home-rev1.jpg";
import avatar2 from "../resources/home-rev2.jpg";
import avatar3 from "../resources/home-rev3.jpg";
import avatar4 from "../resources/home-rev4.jpg";

import "../App.css";

export default function Home() {
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("lg"));

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={styles.container}>
      <Header activeHeaderButtonIndex={0} />
      <Box component="main">
        <Toolbar />
        <div
          className="backimg"
          style={{
            ...styles.titleContainer,
            height: smscreen ? "50vh" : "100vh",
          }}
        >
          <Typography style={styles.title}>Making a difference with</Typography>
          <Typography style={styles.title2}>Excellence</Typography>
        </div>
      </Box>
      <MatthewBanner
        backgroundColor={backgroundColor}
        subtitle="-Matthew 5:41"
        text={`Always give the extra miles to your customers.
Your efforts will be seen and they become loyal clients.`}
      ></MatthewBanner>
      <BusinessCards style={{ marginTop: "5vh", marginBottom: "13vh" }} />
      <Reviews
        style={{ ...styles.reviews, paddingBottom: smscreen ? "5vh" : 0 }}
      >
        <ReviewComponent author="Susy's Kitchen" avatar={avatar1}>
          Het is een team die met liefde voor je klaar staat en denken met je
          mee en ze hebben veel geduld met het uitleggen ik zeer tevreden.
        </ReviewComponent>
        <ReviewComponent author="anthony boschmans" avatar={avatar2}>
          They are very kind, professional and work fast. They will listen to
          your request and try to take it a step further.
        </ReviewComponent>
        <ReviewComponent author="Inaida Antonia" avatar={avatar3}>
          Altri Consultancy was able to take me to the right path. They look
          where I needed help, think together with me and give good advices. I
          recommend them if anyone that needs a boost.
        </ReviewComponent>
        <ReviewComponent author="Bernadette Fluonia" avatar={avatar4}>
          It went great, they did a good job with my pictures for a project of
          mine.
        </ReviewComponent>
      </Reviews>
      <SiteMap />
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    height: "100%",
    minHeight: "100vh",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(30,24,16,0.7)",
  },
  title: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "calc(2rem + 3vw)",
    paddingLeft: "2vw",
    paddingRight: "2vw",
  },
  title2: {
    paddingLeft: "2vw",
    paddingRight: "2vw",
    textAlign: "center",
    color: textColor1,
    fontWeight: "bold",
    fontSize: "calc(2rem + 3vw)",
  },
  reviews: {
    backgroundColor: backgroundColor,
  },
};
