import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MatthewBanner({
  text,
  subtitle,
  backgroundColor = "white",
}) {
  const theme = useTheme();
  const lgscreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor,
        paddingTop: lgscreen ? "10vh" : "5vh",
        paddingBottom: lgscreen ? "10vh" : "5vh",
      }}
    >
      <Container
        style={{ maxWidth: lgscreen ? "800px" : "650px" }}
        maxWidth="md"
      >
        <Typography style={styles.title}>{text}</Typography>
        <Typography style={styles.substitle}>{subtitle}</Typography>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignSelf: "center",
    adjustContent: "center",
  },
  title: {
    padding: 1,
    whiteSpace: "pre-wrap",
    color: "#62615C",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "calc(0.7rem + 0.7vw)",
    textAlign: "center",
  },
  substitle: {
    color: "#62615C",
    fontStyle: "italic",
    fontWeight: "bold",
    paddingTop: "4vh",
    fontSize: "calc(0.7rem + 0.7vw)",
    textAlign: "center",
  },
};
