import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import React from "react";
import {
  LOGO_HEIGHT,
  LOGO_HEIGHT_MOBILE,
  LOGO_WIDTH,
  LOGO_WIDTH_MOBILE,
} from "../../constants/config";
import logoNoText from "../../resources/logo2.png";
import ButtonLink from "./buttons/ButtonLink";

function Logo({ width = LOGO_WIDTH, height = LOGO_HEIGHT, src = logoNoText }) {
  return (
    <a href="/">
      <img
        style={{ paddingTop: "5px" }}
        src={src}
        width={width}
        height={height}
        alt="altri consultancy"
      />
    </a>
  );
}

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 200;
  const theme = useTheme();
  const { window } = props;

  const triggerHyster = useScrollTrigger({ disableHysteresis: true });
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }} style={{ marginTop: "30px" }}>
      <List style={styles.list}>
        <ButtonLink
          isActive={0 === props.activeHeaderButtonIndex}
          text="Home"
          path="/"
        />
        <ButtonLink
          isActive={1 === props.activeHeaderButtonIndex}
          text="Services"
          path="/services"
        />
        <ButtonLink
          isActive={2 === props.activeHeaderButtonIndex}
          text="About"
          path="/aboutus"
        />
        <ButtonLink
          isActive={3 === props.activeHeaderButtonIndex}
          text="Contact"
          path="/contact"
        />
      </List>
    </Box>
  );

  return (
    <div>
      <CssBaseline />
      <Slide
        elevation={triggerHyster || smallscreen ? 4 : 0}
        appear={false}
        direction="down"
        in={smallscreen ? true : !trigger}
      >
        <AppBar component="nav" style={styles.appbar}>
          <Container style={{ padding: 0 }} maxWidth="md">
            <Toolbar style={styles.toolbar}>
              <Box
                sx={{ mr: 2, display: { sm: "none", xs: "flex" } }}
                style={styles.toolbarMobile}
              >
                <div underline="none" href="/" style={styles.toolbarMobileLogo}>
                  <Logo width={LOGO_WIDTH_MOBILE} height={LOGO_HEIGHT_MOBILE} />
                </div>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { md: "none" } }}
                >
                  <MenuIcon
                    style={{
                      width: LOGO_WIDTH_MOBILE * 0.6,
                      height: LOGO_HEIGHT_MOBILE * 0.6,
                    }}
                  />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ButtonLink
                  isActive={0 === props.activeHeaderButtonIndex}
                  text="Home"
                  path="/"
                />
                <ButtonLink
                  isActive={1 === props.activeHeaderButtonIndex}
                  text="Services"
                  path="/services"
                />
              </Box>
              <Box
                sx={{ display: { xs: "none", sm: "block" } }}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <Logo />
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ButtonLink
                  isActive={2 === props.activeHeaderButtonIndex}
                  text="About"
                  path="/aboutus"
                />
                <ButtonLink
                  isActive={3 === props.activeHeaderButtonIndex}
                  text="Contact"
                  path="/contact"
                />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Slide>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}

const styles = {
  appbar: {
    background: "white",
    paddingTop: "5px",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: 0,
    justifyContent: "center",
    alignContent: "center",
  },
  logo: {
    width: LOGO_WIDTH,
    height: LOGO_HEIGHT,
  },
  logoText: {
    color: "black",
    fontSize: "10px",
    textTransform: "uppercase",
  },
  drawerItem: {
    marginBottom: "15px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "2vh",
  },
  toolbarMobile: {
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  toolbarMobileLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    cursor: "pointer",
    marginLeft: "24px",
  },
};
